.header-top {
  background-color: #07294d;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}

.sivasoft-logo {
  width: 50px;
  height: 50px;
  vertical-align: bottom;
  border: 2px solid #ffc600;
  border-radius: 25px;
}

.sivasoft-text {
  font-size: 40px;
  color: #07294d;
  margin-bottom: -6px;
  font-weight: 500;
}

a {
  color: #007bff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

i,
span,
a {
  display: inline-block;
}

img {
  max-width: 100%;
}

.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #ffc600;
  padding: 0 35px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 50px;
  border-radius: 5px;
  color: #07294d;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 5;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  background-color: #ffc600;
}

.main-btn:hover {
  color: #ffffff;
  border-color: #07294d;
  background-color: #07294d;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #505050;
  margin: 0px !important;
}

.cont span {
  font-size: 18px;
  color: #07294d;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.bi {
  color: #ffc600;
  margin-left: 10px;
  margin-top: 3px;
}

.footer-top {
  background-color: #07294d;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-40 {
  padding-top: 40px;
}

.footer-about {
  padding-right: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.footer-about p {
  color: #fff;
  padding-top: 15px;
}

.pb-25 {
  padding-bottom: 25px;
}

.footer-title h6 {
  font-size: 18px;
  color: #fff;
}

.footer-address ul li {
  position: relative;
  margin-bottom: 10px;
}

.footer-address ul li .icon i {
  font-size: 18px;
  color: #ffc600;
  position: absolute;
  left: 0;
  top: 0px;
}

.bi {
  color: #ffc600;
  margin-left: 15px;
}

.footer-copyright {
  background-color: #021d3a;
}

.pb-25 {
  padding-bottom: 25px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.footer-copyright .copyright p {
  color: #fff;
}

.footer-address ul li .cont p {
  color: #fff;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.footer-address ul li .cont {
  padding-left: 35px;
}

.sivasoft-footer-text {
  font-size: 40px;
  color: #ffffff;
  margin-bottom: -6px;
  font-weight: 500;
  margin-left: 5px;
}

.width40 {
  width: 40%;
}

.scroll-top-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.left {
  list-style-image: url(https://sivasoft.in/images/double-arrow-bullet.png);
}

.header-top {
  background-color: #07294d;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}

.header-logo-support {
  border-bottom: 1px solid #cecece;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;

}

.mt-20 {
  margin-top: 20px;
}

.navigation .navbar {
  padding: 0;
}

/*===== Header Menu =====*/

.navigation {}

.navigation.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
  border-bottom: 1px solid #cecece;
  -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  -webkit-animation: sticky 2s;
  animation: sticky 2s;
}

@-webkit-keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%)
  }
}

@keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%)
  }
}

.navigation .navbar {
  padding: 0;
}

.navigation .navbar .navbar-nav {}

.navigation .navbar .navbar-nav li {
  margin-right: 40px;
  position: relative;
}

.navigation .navbar .navbar-nav li:last-child {
  margin-right: 0;
}

.navigation .navbar .navbar-nav li a {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #07294d;
  text-transform: uppercase;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  padding: 25px 0;
}

.navigation .navbar .navbar-nav>li>a.active,
.navigation .navbar .navbar-nav>li>a:hover {
  color: #ffc600;
}

.navigation .navbar .navbar-nav li .sub-menu {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #07294d;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 99;
}

.navigation .navbar .navbar-nav li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.navigation .navbar .navbar-nav li .sub-menu li {
  margin-right: 0;
}

.navigation .navbar .navbar-nav li .sub-menu li a {
  padding: 10px 15px;
  display: block;
  border-bottom: 1px solid rgba(255, 198, 0, 0.5);
  color: #fff;
}

.navigation .navbar .navbar-nav li .sub-menu li a.active,
.navigation .navbar .navbar-nav li .sub-menu li a:hover {
  padding-left: 20px;
  color: #ffc600;
}

.sub-nav-toggler {
  display: none;
}

.navigation .navbar .navbar-nav li .sub-menu li:last-child a {
  border-bottom: 0;
}

.navbar .navbar-toggler {
  padding: 3px 8px;
  margin: 18px 0;
  -webkit-transform: all 0.4s linear;
  transform: all 0.4s linear;
}

.navbar .navbar-toggler .icon-bar {
  width: 30px;
  height: 2px;
  background-color: #07294d;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.navbar .navbar-toggler.active .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(46deg);
  transform: rotate(46deg);
  top: 7px;
}

.navbar .navbar-toggler.active .icon-bar:nth-of-type(2) {
  opacity: 0;
}

.navbar .navbar-toggler.active .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(134deg);
  transform: rotate(134deg);
  top: -7px;
}

.right-icon {}

.right-icon ul li {
  display: inline-block;
  margin-right: 17px;
  padding: 22px 0;
}

.right-icon ul li:last-child {
  margin-right: 0;
}

.right-icon ul li a {
  font-size: 18px;
  color: #07294d;
  position: relative;
  padding-right: 7px;
}

.right-icon ul li a span {
  font-size: 12px;
  color: #fff;
  background-color: #ffc600;
  border-radius: 50%;
  padding: 0px 4px;
  position: absolute;
  top: -5px;
  right: 0;
}

/*===== Index-2 =====*/

.header-social ul li {
  display: inline-block;
  margin-left: 10px;
}

.header-social ul li:first-child {
  margin-left: 0;
}

.header-social ul li a {
  font-size: 15px;
  color: #dee2e6;
}

.navigation-2 .navbar .navbar-nav li {
  margin-right: 40px;
}

.navigation-2 .navbar .navbar-nav li a {
  padding: 45px 0;
}

.navigation-2 .right-icon ul li {
  padding: 42px 0;
}

.navigation-2.sticky .navbar .navbar-nav li a {
  padding: 30px 0;
}

.navigation-2.sticky .right-icon ul li {
  padding: 25px 0;
}

/*===== Index-3 =====*/

.navigation-3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: none;
  z-index: 99;
}

.navigation-3 .navbar .navbar-nav li a,
.navigation-3 .right-icon ul li a {
  color: #fff;
}

.navigation-3.sticky .navbar .navbar-nav li a,
.navigation-3.sticky .right-icon ul li a {
  color: #07294d;
}

.navigation-3.sticky .navbar .navbar-nav>li>a.active,
.navigation-3.sticky .navbar .navbar-nav>li>a:hover {
  color: #ffc600;
}

.navigation-3 .navbar .navbar-toggler .icon-bar {
  background-color: #fff;
}

.navigation-3.sticky .navbar .navbar-toggler .icon-bar {
  background-color: #07294d;
}


/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199.98px) {

.navigation .navbar .navbar-nav li {
	margin-right: 35px;
}
.singel-course .cont {
	padding-left: 25px;
	padding-right: 25px;
}
.singel-course .course-teacher .name {
	margin-left: 5px;
}
.singel-course .course-teacher .admin ul li {
	margin-right: 5px;
}

.feature ul li .singel-feature .icon {
	padding-right: 20px;
}
.feature ul li .singel-feature .cont {
	width: 75%;
	float: left;
	overflow: hidden;
}

.singel-publication .cont {
	display: block;
}
.singel-publication .cont .name,
.singel-publication .cont .button {
	width: 100%;
    
}
.singel-publication .cont .button {
    text-align: left !important;
    margin-top: 10px;
}


.navigation-2 .navbar .navbar-nav li {
	margin-right: 17px;
}
.navigation-2 .navbar .navbar-nav li a {
	font-size: 13px;
}
.right-icon ul li a {
	font-size: 15px;
}
.right-icon ul li {
	margin-right: 3px;
}

.category-form {
	margin-top: -197px;
}
.singel-course-2 > .thum .course-teacher .review {
	margin-left: 50px;
}
.singel-course-2 > .thum .price {
	bottom: 70px;
}

.slider-cont-3 {
	padding: 50px 60px 60px;
}

.slider-cont-3 h2 {
	font-size: 42px;
}

.category-form-3{
    margin-top: 0;
}

.count-down-cont h2,
.count-down-time .singel-count .number{
    font-size: 62px;
}


.corses-singel-left {
	padding: 35px 40px;
}
.corses-singel-left .course-terms > ul > li {
	margin-right: 23px;
}
.course-features .price-button .main-btn {
	float: none;
	margin-top: 15px;
}
.You-makelike .singel-makelike .cont {
	padding: 0 20px;
}

.singel-event-list {
	padding: 20px;
}

.events-coundwon {
	padding: 10px 20px 20px;
}
.events-coundwon .count-down-time .singel-count .title{
    font-size: 12px;
}
.events-address {
	padding: 0 20px 20px;
}

.teachers-right .tab-content {
	padding: 0px 35px 20px;
}
.teachers-left {
	padding: 25px 35px 20px;
}

.saidbar .saidbar-search {
	padding: 25px;
}
.saidbar .categories {
	padding: 20px 25px 15px;
}

.saidbar .saidbar-post {
	padding: 20px 25px 25px;
}

.contact-from {
	padding: 40px;
}

.contact-address {
	padding: 10px 40px 40px;
}



}






 
/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991.98px) {


.navigation .navbar .navbar-nav li {
	margin-right: 0;
}
.navigation .navbar .navbar-nav li a {
	padding: 10px 0;
}
.navigation .navbar .navbar-nav li .sub-menu {
	position: relative !important;
	width: 100% !important;
	left: 0 !important;
	top: auto !important;
	opacity: 1 !important;
	visibility: visible !important;
	display: none;
	right: auto;
	-webkit-transition: none;
	transition: none;
	-webkit-transform: translateX(0%);
	transform: translateX(0%);
	text-align: left;
}
.navigation .navbar .navbar-nav li a .sub-nav-toggler {
	position: absolute;
	padding: 5px;
	background: none;
	border: 0;
	top: 5px;
	right: 0;
	cursor: pointer;
    display: block;
}

.category-text{
    text-align: center;
}

.apply-color-1 {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
}

.apply-color-2 {
	border-top-right-radius: 0;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.feature-bg {
	top: auto;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
}
.video {
	padding-left: 0;
	position: relative;
	z-index: 5;
}

.navigation-2.navigation .navbar {
	padding: 20px 0;
}
.navigation-2.navigation.sticky .navbar {
	padding: 0 0;
}
.navigation-2.sticky .navbar .navbar-nav li a {
	padding: 10px 0;
}
.category-form {
	margin-top: 50px;
}

.navigation-3 .navbar-collapse{
    background-color: #fff;
    padding: 0 15px;
}
.navigation-3 .navbar .navbar-nav li a{
    color: #07294d;
}

.slider-cont-3 {
	padding: 25px 35px 35px;
}
.slider-cont-3 h2 {
	font-size: 38px;
}

.slider-cont-3 span {
	font-size: 22px;
}

.corses-singel-left .course-terms > ul > li {
	margin-right: 50px;
}
.course-features .price-button .main-btn {
	float: none;
	margin-top: 15px;
}
.teachers-right .tab-content {
	padding: 0px 35px 20px;
}

.single-slider {
	padding-bottom: 200px;
	padding-top: 100px;
}
.category {
	margin-top: -100px;
}

#about-part {
	padding-bottom: 170px;
	padding-top: 15px;
}

#apply-aprt {
	padding-bottom: 70px;
}
#course-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#video-feature {
	padding-top: 10px;
	padding-bottom: 70px;
}

#teachers-part {
	padding-top: 15px;
	padding-bottom: 70px;
}
#publication-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#testimonial {
	padding-top: 65px;
	padding-bottom: 65px;
}
#news-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#patnar-logo {
	padding-top: 0;
	padding-bottom: 30px;
}

.slider-2.single-slider {
	padding-top: 130px;
	padding-bottom: 150px;
}
#counter-part {
	padding-top: 15px;
	padding-bottom: 60px;
}
#event-part {
	padding-top: 70px;
}
#category-3 {
	padding-top: 40px;
	padding-bottom: 70px;
}
#slider-part-3 {
	position: relative;
	padding-top: 150px;
	padding-bottom: 70px;
}
#count-down-part {
	padding-top: 10px;
	padding-bottom: 70px;
}
#event-part.pb-120 {
	padding-bottom: 70px;
}

#page-banner {
	padding-top: 85px;
	padding-bottom: 90px;
}

#about-page {
	padding-top: 15px;
	padding-bottom: 65px;
}
.about-items.pt-60 {
	padding-top: 25px;
}

#courses-part {
	padding-top: 70px;
	padding-bottom: 70px;
}
#corses-singel {
	padding-top: 40px;
	padding-bottom: 70px;
}
.releted-courses.pt-95 {
	padding-top: 45px;
}
#event-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#event-singel {
	padding-top: 70px;
	padding-bottom: 70px;
}
.events-coundwon {
	margin-top: 65px;
}
#teachers-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#teachers-singel {
	padding-top: 20px;
	padding-bottom: 70px;
}
#blog-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#blog-singel {
	padding-top: 40px;
	padding-bottom: 70px;
}
#shop-page {
	padding-top: 70px;
	padding-bottom: 70px;
}
#shop-singel {
	padding-top: 70px;
	padding-bottom: 70px;
}
.releted-item {
	padding-top: 55px;
}
#contact-page {
	padding-top: 40px;
	padding-bottom: 70px;
}





}

 
/* small mobile :320px. */
@media (min-width: 576px) and (max-width: 767.98px) {

.navigation .navbar .navbar-nav li {
	margin-right: 0;
}
.navigation .navbar .navbar-nav li a {
	padding: 10px 0;
}
.navigation .navbar .navbar-nav li .sub-menu {
	position: relative !important;
	width: 100% !important;
	left: 0 !important;
	top: auto !important;
	opacity: 1 !important;
	visibility: visible !important;
	display: none;
	right: auto;
	-webkit-transition: none;
	transition: none;
	-webkit-transform: translateX(0%);
	transform: translateX(0%);
	text-align: left;
}
.navigation .navbar .navbar-nav li a .sub-nav-toggler {
	position: absolute;
	padding: 5px;
	background: none;
	border: 0;
	top: 5px;
	right: 0;
	cursor: pointer;
    display: block;
}

.logo {
	text-align: center;
}

.support-button {
	float: none !important;
	overflow: hidden;
}
.support-button .support {
	margin-left: 40px;
}

.slider-cont h1 {
	font-size: 46px;
}

.category-text{
    text-align: center;
}

.apply-color-1 {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
}

.apply-color-2 {
	border-top-right-radius: 0;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.feature-bg {
	top: auto;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
}
.video {
	padding-left: 0;
	position: relative;
	z-index: 5;
}
	
.navigation-2.navigation .navbar {
	padding: 20px 0;
}
.navigation-2.navigation.sticky .navbar {
	padding: 0 0;
}
.navigation-2.sticky .navbar .navbar-nav li a {
	padding: 10px 0;
}
.category-form {
	margin-top: 50px;
}

.navigation-3 .navbar-collapse{
    background-color: #fff;
    padding: 0 15px;
}
.navigation-3 .navbar .navbar-nav li a{
    color: #07294d;
}

.slider-cont-3 {
	padding: 15px 25px 25px;
}
.slider-cont-3 h2 {
	font-size: 38px;
}
.slider-cont-3 span {
	font-size: 22px;
}

.count-down-cont h2,
.count-down-time .singel-count .number{
    font-size: 52px;
}

#page-banner {
	padding-top: 100px;
	padding-bottom: 100px;
}

.courses-top-search {
	text-align: center;
}
.courses-search{
	float: none !important;
    display: inline-block;
}
.courses-top-search .nav {
	float: none !important;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
    padding-bottom: 15px;
}

#courses-list .singel-course .thum .price {
    top: auto;
	right: 60px;
	bottom: -24px;
}
.page-banner-cont h2 {
	font-size: 44px;
}

.corses-singel-left .course-terms > ul > li {
	margin-right: 50px;
}
.corses-singel-left .course-terms ul li .review {
	margin-left: 60px;
	margin-top: 15px;
}
.corses-tab .nav .nav-item a {
	font-size: 15px;
}

.corses-singel-left {
	padding: 20px 30px;
	background-color: #fff;
}

.singel-event-list {
	padding: 20px;
}

.events-area {
	padding: 25px 30px 30px;
}

.singel-blog .blog-cont {
	padding: 20px 30px;
}
.singel-blog .blog-cont a h3 {
	font-size: 28px;
}
.leave-comment {
	padding: 0 30px 25px;
}

.shop-destails .shop-left {
	padding-right: 0;
}

.blog-details .cont {
	padding: 20px 30px;
	background-color: #fff;
}
.footer-about {
	padding-right: 0;
}

.single-slider {
	padding-bottom: 200px;
	padding-top: 100px;
}
.category {
	margin-top: -100px;
}

#about-part {
	padding-bottom: 170px;
	padding-top: 15px;
}

#apply-aprt {
	padding-bottom: 70px;
}
#course-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#video-feature {
	padding-top: 10px;
	padding-bottom: 70px;
}

#teachers-part {
	padding-top: 15px;
	padding-bottom: 70px;
}
#publication-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#testimonial {
	padding-top: 65px;
	padding-bottom: 65px;
}
#news-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#patnar-logo {
	padding-top: 0;
	padding-bottom: 30px;
}

.slider-2.single-slider {
	padding-top: 130px;
	padding-bottom: 150px;
}
#counter-part {
	padding-top: 15px;
	padding-bottom: 60px;
}
#event-part {
	padding-top: 70px;
}
#category-3 {
	padding-top: 40px;
	padding-bottom: 70px;
}
#slider-part-3 {
	position: relative;
	padding-top: 150px;
	padding-bottom: 70px;
}
#count-down-part {
	padding-top: 10px;
	padding-bottom: 70px;
}
#event-part.pb-120 {
	padding-bottom: 70px;
}

#page-banner {
	padding-top: 85px;
	padding-bottom: 90px;
}

#about-page {
	padding-top: 15px;
	padding-bottom: 65px;
}
.about-items.pt-60 {
	padding-top: 25px;
}

#courses-part {
	padding-top: 70px;
	padding-bottom: 70px;
}
#corses-singel {
	padding-top: 40px;
	padding-bottom: 70px;
}
.releted-courses.pt-95 {
	padding-top: 45px;
}
#event-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#event-singel {
	padding-top: 70px;
	padding-bottom: 70px;
}
.events-coundwon {
	margin-top: 65px;
}
#teachers-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#teachers-singel {
	padding-top: 20px;
	padding-bottom: 70px;
}
#blog-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#blog-singel {
	padding-top: 40px;
	padding-bottom: 70px;
}
#shop-page {
	padding-top: 70px;
	padding-bottom: 70px;
}
#shop-singel {
	padding-top: 70px;
	padding-bottom: 70px;
}
.releted-item {
	padding-top: 55px;
}
#contact-page {
	padding-top: 40px;
	padding-bottom: 70px;
}








    

}
 
/* Large Mobile :480px. */
@media only screen and  (max-width: 575.98px) {

.main-btn {
	padding: 0 20px;
	font-size: 14px;
	font-weight: 700;
	line-height: 45px;
}

.section-title h2 {
	font-size: 26px;
}

.navigation .navbar .navbar-nav li {
	margin-right: 0;
}
.navigation .navbar .navbar-nav li a {
	padding: 10px 0;
}
.navigation .navbar .navbar-nav li .sub-menu {
	position: relative !important;
	width: 100% !important;
	left: 0 !important;
	top: auto !important;
	opacity: 1 !important;
	visibility: visible !important;
	display: none;
	right: auto;
	-webkit-transition: none;
	transition: none;
	-webkit-transform: translateX(0%);
	transform: translateX(0%);
	text-align: left;
}
.navigation .navbar .navbar-nav li a .sub-nav-toggler {
	position: absolute;
	padding: 5px;
	background: none;
	border: 0;
	top: 5px;
	right: 0;
	cursor: pointer;
    display: block;
}
.navigation-2.navigation .navbar .navbar-brand {
	width: 145px;
}

.search-box .serach-form input {
	width: 80%;
}
.search-box .serach-form button {
	left: 80%;
}
.logo {
	text-align: center;
}

.header-contact ul li {
	display: block;
	margin-right: 0;
}

.support-button {
	float: none !important;
	overflow: hidden;
	text-align: center;
}
.support-button .support{
    padding-bottom: 20px;
    text-align: left;
}
.support-button .support, .support-button .button {
	margin-left: 0;
	float: none !important;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.slider-cont h1 {
	font-size: 30px;
}
.slider-cont p {
	font-size: 16px;
	font-weight: 400;
}

.category-text{
    text-align: center;
}

.category-slied .prev.slick-arrow {
	left: -30px;
}
.category-slied .slick-arrow {
	right: -30px;
}

.about-event {
	padding: 30px 35px 10px;
}
.about-event .event-title h3 {
	font-size: 28px;
}
.about-event ul li .singel-event h4 {
	font-size: 18px;
}
.about-bg {
	width: 100%;
}
.apply .apply-cont {
	padding: 25px 30px 30px;
}
.apply-color-1 {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
}

.apply-color-2 {
	border-top-right-radius: 0;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.singel-course .cont h4 {
	font-size: 20px;
}

.singel-course .course-teacher .admin {
	float: none;
	margin-left: 55px;
}

.feature-bg {
	top: auto;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
}
.video {
	padding-left: 0;
	position: relative;
	z-index: 5;
}

.feature ul li .singel-feature .icon {
	float: none;
	display: block;
	padding-right: 0;
}

.feature ul li .singel-feature .cont {
	width: 100%;
	float: none;
	margin-top: 30px;
}
.products-btn {
	text-align: left !important;
}
.singel-testimonial .testimonial-thum {
	position: relative;
	display: inline-block;
	left: 50%;
	top: 0;
	margin-top: 25px;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
.singel-testimonial .testimonial-cont {
	padding-left: 0;
	padding-top: 20px;
}


.navigation-2.navigation .navbar {
	padding: 20px 0;
}
.navigation-2.navigation.sticky .navbar {
	padding: 0 0;
}
.navigation-2.sticky .navbar .navbar-nav li a {
	padding: 10px 0;
}
.category-form {
	margin-top: 50px;
}

.navigation-3 .navbar-collapse{
    background-color: #fff;
    padding: 0 15px;
}
.navigation-3 .navbar .navbar-nav li a{
    color: #07294d;
}

.singel-course-2 > .thum .course-teacher .review {
	margin-left: 50px;
    display: block;
}
.singel-course-2 > .thum .price {
	bottom: 70px;
}

.category-form .form-title h3 {
	font-size: 28px;
}
.category-form .form-title h3 {
	font-size: 28px;
}

.category-form .main-form {
	padding-left: 20px;
	padding-right: 20px;
}
.happy-student .happy-title h3 {
	font-size: 28px;
}
.happy-student {
	padding-top: 35px;
	padding-left: 30px;
	padding-right: 30px;
}
.happy-student .singel-student p {
	padding-right: 0;
}
.happy-student .student-image {
	text-align: right;
}
.happy-student .student-image img {
	width: 60%;
}
.event-2 {
	padding: 40px 25px 10px;
}

.slider-cont-3 {
	padding: 15px 25px 25px;
}
.slider-cont-3 h2 {
	font-size: 38px;
}
.slider-cont-3 span {
	font-size: 22px;
}
.slider-search input {
	border-left: 0;
	border-top: 1px solid #a8a8a8;
}

.count-down-cont h3 {
	font-size: 20px;
}
.count-down-cont h2,
.count-down-time .singel-count .number{
    font-size: 32px;
}

.page-banner-cont h2 {
	font-size: 40px;
}

.courses-top-search {
	text-align: center;
}
.courses-search{
	float: none !important;
    display: inline-block;
}
.courses-top-search .nav {
	float: none !important;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
    padding-bottom: 15px;
}

.courses-pagination .pagination .page-item a {
	font-size: 14px;
	width: 34px;
	height: 34px;
	line-height: 30px;
}

#courses-list .singel-course .thum .price {
    top: auto;
	right: 60px;
	bottom: -24px;
}
.courses-search input {
	width: 100%;
}

.corses-singel-left {
	padding: 20px 30px;
	background-color: #fff;
}

.corses-singel-left .title h3 {
	font-size: 24px;
}
.corses-singel-left .course-terms > ul > li {
	margin-right: 0;
}
.corses-singel-left .course-terms ul li .course-category,
.corses-singel-left .course-terms ul li .review {
	margin-top: 15px;
}

.overview-description {
	padding: 0px 5px 25px;
}

.curriculam-cont {
	padding: 25px 5px;
}

.instructor-cont {
	padding: 30px 5px 25px;
}
.instructor-cont .instructor-author .author-thum {
	float: none;
}
.instructor-cont .instructor-author .author-name {
	float: none;
	margin-top: 20px;
}
.corses-tab .nav .nav-item {
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	width: 50%;
}

.reviews-cont {
	padding: 25px 5px 30px;
}

.course-features .price-button .main-btn {
	float: none;
	margin-top: 15px;
}

.You-makelike .singel-makelike .cont {
	padding: 0 15px;
}

.You-makelike .singel-makelike .cont h4 {
	font-size: 16px;
}

.singel-event-list {
	display: block;
}
.singel-event-list .event-thum{
    width: 100%;
}
.singel-event-list .event-cont{
	width: 100%;
    padding-left: 0;
    padding-top: 30px;
}

.events-coundwon {
	padding: 10px 20px 20px;
}
.events-coundwon .count-down-time .singel-count .title{
    font-size: 12px;
}
    
.events-area {
	padding: 20px 25px 25px;
}
.teachers-left {
	padding: 15px 15px 10px;
}
.teachers-right .tab-content {
	padding: 0px 10px 5px;
}

.teachers-right .nav-justified .nav-item{
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    width: 50%;
}
.singel-blog .blog-cont {
	padding: 20px 30px;
}
.singel-blog .blog-cont a h3 {
	font-size: 28px;
}

.saidbar .saidbar-search {
	padding: 25px;
}
.saidbar .categories {
	padding: 20px 25px 15px;
}

.saidbar .saidbar-post {
	padding: 20px 25px 25px;
}
.saidbar .saidbar-post ul li a .singel-post .cont h6 {
	font-size: 16px;
}
.leave-comment {
	padding: 0 30px 25px;
}

.leave-comment h4 {
	font-size: 22px;
}
#shop-list .singel-publication .cont {
    padding-top: 25px;
}
.shop-destails .shop-left {
	padding-right: 0;
}
.shop-destails {
	padding: 0px 30px 30px;
}
.shop-reviews .nav .nav-item {
	width: 50%;
}
.shop-reviews .nav .nav-item a {
	width: 100%;
    display: block;
}

.shop-reviews .tab-content {
	padding: 0px 15px 10px;
}

.contact-from {
	padding: 30px;
}

.contact-address {
	padding: 30px;
}

.blog-details .cont {
	padding: 5px 10px;
}
.blog-details .cont h3 {
	font-size: 28px;
}
.blog-details .cont ul li {
	display: block;
	line-height: 30px;
}
.blog-details .cont .share li.title {
	font-size: 15px;
}
.blog-details .cont .share > li {
	margin-right: 5px;
}
.blog-details .cont .share li a i {
	width: 30px;
	line-height: 30px;
	font-size: 14px;
}
.blog-details .cont .blog-comment ul li .replay {
	padding-left: 0;
}
.course-features .price-button span {
	display: block;
}

.shop-top-search {
	text-align: center;
    padding: 15px;
}
.shop-top-search .shop-select {
	float: none;
	margin-top: 15px;
}
.footer-about {
	padding-right: 0;
}

.single-slider {
	padding-bottom: 200px;
	padding-top: 100px;
}
.category {
	margin-top: -100px;
}

#about-part {
	padding-bottom: 170px;
	padding-top: 15px;
}

#apply-aprt {
	padding-bottom: 70px;
}
#course-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#video-feature {
	padding-top: 10px;
	padding-bottom: 70px;
}

#teachers-part {
	padding-top: 15px;
	padding-bottom: 70px;
}
#publication-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#testimonial {
	padding-top: 65px;
	padding-bottom: 65px;
}
#news-part {
	padding-top: 65px;
	padding-bottom: 70px;
}
#patnar-logo {
	padding-top: 0;
	padding-bottom: 30px;
}

.slider-2.single-slider {
	padding-top: 130px;
	padding-bottom: 150px;
}
#counter-part {
	padding-top: 15px;
	padding-bottom: 60px;
}
#event-part {
	padding-top: 70px;
}
#category-3 {
	padding-top: 40px;
	padding-bottom: 70px;
}
#slider-part-3 {
	position: relative;
	padding-top: 150px;
	padding-bottom: 70px;
}
#count-down-part {
	padding-top: 10px;
	padding-bottom: 70px;
}
#event-part.pb-120 {
	padding-bottom: 70px;
}

#page-banner {
	padding-top: 85px;
	padding-bottom: 90px;
}

#about-page {
	padding-top: 15px;
	padding-bottom: 65px;
}
.about-items.pt-60 {
	padding-top: 25px;
}

#courses-part {
	padding-top: 70px;
	padding-bottom: 70px;
}
#corses-singel {
	padding-top: 40px;
	padding-bottom: 70px;
}
.releted-courses.pt-95 {
	padding-top: 45px;
}
#event-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#event-singel {
	padding-top: 70px;
	padding-bottom: 70px;
}
.events-coundwon {
	margin-top: 65px;
}
#teachers-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#teachers-singel {
	padding-top: 20px;
	padding-bottom: 70px;
}
#blog-page {
	padding-top: 40px;
	padding-bottom: 70px;
}
#blog-singel {
	padding-top: 40px;
	padding-bottom: 70px;
}
#shop-page {
	padding-top: 70px;
	padding-bottom: 70px;
}
#shop-singel {
	padding-top: 70px;
	padding-bottom: 70px;
}
.releted-item {
	padding-top: 55px;
}
#contact-page {
	padding-top: 40px;
	padding-bottom: 70px;
}

}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.program-highlight {
  padding: 1rem;
  text-align: center;
}

.divider {
  border: none;
  height: 1px;
  background-color: #ccc;
  /* Adjust color as needed */
  margin: 1rem 0;
}

.program-title {
  color: green;
  /* Adjust color as needed */
  font-weight: bold;
}

.original-price {
  color: red;
  text-decoration: line-through;
}

.offer-separator {
  font-weight: bold;
  margin: 0 0.5rem;
}

.offer-price {
  color: green;
  font-weight: bold;
}

.career-comparison h3,
.career-training-info h2 {
  margin-bottom: 1rem;
}

.dark-header {
  color: #07294d;
}

.custom-thead-bg {
  background-color: #007bff;
  color: white; /* Optional: Adjust text color for contrast */
}